@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;500;700;900&display=swap');

body {
  color: var(--text-color) !important;
  font-family: 'Roboto', sans-serif !important;
  overflow: visible !important;
}
:root {
  --title-bg_color: rgb(150 106 77/1);
  --white: #ffffff;
  /* --table_h_color:rgb(150 106 77); */
  --border-color: #dee2e6;
  /* --bg-color:#f7f3f1; */
  --main-bg-color: rgb(150 106 77/1);
  --title-color: rgb(150 106 77);
  --text-color: #282829;
}
* {
  /* font-family: "Montserrat", sans-serif; */
  /* font-family: 'Roboto', sans-serif !important; */
  box-sizing: content-box;
  font-size: 1rem;
  text-align: left;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  color: var(--title-color) !important;
  font-family: 'Roboto', sans-serif !important;
}

/* date ui changes for i-claw */
/* .clndr-calendars-segment.clndr-floating {
  left: -100px;
} */

.date_style .ui.segment.clndr-calendars-segment.clndr-floating.clndr-left {
  left: -100px;
}
button.ui.compact.fluid.button.clndr-button-today {
  display: none;
}
.modal-dialog button.btn-close {
  background: white;
  color: var(--title-color) !important;
  padding: 16px 16px;
  opacity: 2;
}
.clndr-control {
  background: #a4a4a4;
  padding: 10px 0;
  border-radius: 3px;
}
/* date ui ramneek */

/* nores button */
.notes_buttonAttach {
  width: 100% !important;
  padding: 9px 16px !important;
}
.app_view_box.app_view_box5 .ui.icon.input > input {
  width: auto !important;
}
button.ui.compact.fluid.disabled.button.clndr-button-today {
  display: none;
}
.clndr-calendars-segment.clndr-floating {
  position: absolute !important;
  z-index: 222 !important;
}

input,
select {
  text-align: left;
  padding: 0.3rem 0.4rem;
  margin: 0.5rem 0 0.5rem 0.8rem;
  border: 1px solid #a4a4a4;
  color: #a2a2a2;
  width: 50%;
}

*,
:after,
:before {
  box-sizing: border-box !important;
}
.form select {
  background-color: transparent;
  padding: 0.3rem 0.8rem;
  /* margin: 0.5rem 0 0.5rem 0.8rem; */
  color: var(--text-color) !important;
  /* height: 42px; */
  /* background: #f7f3f0; */
  /* border: none; */
  margin-left: -18px;
  margin-top: -18px;
}

/* button {
  padding: 0.5rem 1rem;
  background-color: transparent;
  margin: 0.5rem 0;
} */

.imageCover {
  max-width: 112px;
  height: auto;
  margin-bottom: 0;
  margin: 0 auto 40px;
  display: block;
}

/* body {
  background: #fff !important;
} */
.form {
  max-width: 800px;
  margin: 20px 0 30px 30px;
  /* width: 100%; */
  align-items: center;
  background: #ffff;
  /* box-shadow: 2px 3px 13px 4px #e8e8e8; */
  border-radius: 25px;
  padding: 45px;
  /* padding: 20px; */
  position: relative;
}

input,
select,
textarea {
  text-align: left;
  padding: 5px 10px !important;
  margin: 0;
  color: #a2a2a2;
  display: block;
  /* background: #f7f3f0; */
  border: none;
  width: 50%;
  margin-bottom: 20px !important;
}

.fullinput input {
  width: 100%;
}

.fotter-btn {
  display: flex;
  justify-content: space-between;
}

.second {
  justify-content: flex-end;
}

h3 {
  font-size: 25px;
  font-family: auto;
}

h6 {
  font-size: 14px;
}

label {
  color: var(--text-color);
  font-weight: 500;
  /* text-transform: uppercase; */
}

.form.print {
  padding: 25px;
}

input:placeholder {
  font-size: 20px;
}

.form.print div {
  font-size: 15px;
  color: #343434;
}

.form.print button {
  float: right;
  border: 2px solid #c7b0a0;
  width: 82px;
  text-align: center;
  font-size: 14px;
}

/* button {
  border: 2px solid #c7b0a0;
  width: 82px;
  text-align: center;
} */
button {
  border: 2px solid var(--title-color);
  width: 82px;
  text-align: center;
  background: var(--title-color);
  color: #fff !important;
  width: auto !important;
  border-radius: 15px !important;
  padding: 9px 25px;
  font-weight: 400 !important;
  display: flex;
  align-items: center;
}

/* .next {
  font-size: 14px;
  font-weight: 600;
  color: #b5c9d2;
} */
/* .preview {
  font-size: 14px;
  font-weight: 600;
  color: #b5c9d2;
} */
.login {
  width: 410px;
  background: #fff;
  border-radius: 10px;
  overflow: hidden;
  padding: 0px 55px 63px 55px;
  box-shadow: 0 5px 10px 0px rgba(0, 0, 0, 0.1);
  -moz-box-shadow: 0 5px 10px 0px rgba(0, 0, 0, 0.1);
  -webkit-box-shadow: 0 5px 10px 0px rgba(0, 0, 0, 0.1);
  -o-box-shadow: 0 5px 10px 0px rgba(0, 0, 0, 0.1);
  -ms-box-shadow: 0 5px 10px 0px rgba(0, 0, 0, 0.1);
  margin: 0 auto;
}

input[type='text'] {
  padding-left: 7px;
  font-size: 15px;
}

:focus {
  outline: -webkit-focus-ring-color auto 0px;
}

.heading__top h2 {
  font-size: 24px;
  font-weight: 700;
  margin: 35px 0 25px 45px;
}
.login label {
  font-weight: 400;
}

input.button-login {
  display: block;
  position: relative;
  z-index: 1;
  border-radius: 25px;
  overflow: hidden;
  margin: 0 auto;
  background: #9b6f52;
  text-align: center;
  width: 252px;
  margin-bottom: 32px;
  color: #fff;
}

a.sign {
  font-family: Poppins-Regular;
  font-size: 16px;
  color: #666666;
  line-height: 1.5;
  text-align: center;
  margin: 0 auto;
  display: block;
  text-decoration: none;
}

i.ICON__Arrow:hover {
  background-color: white;
  color: var(--title-color);
  font-weight: 900;
  transform: scale(1.2);
  transition: 0.3s;
}
.css-1nv3feb::before {
  background-color: initial !important;
}
.login input {
  width: 100%;
  background: transparent;
  border-radius: 0;
  border-bottom: 2px solid #9b6f52;
  margin-bottom: 37px;
}

.login img {
  margin-bottom: 70px;
}

input.button-login {
  width: 100%;
  display: block;
  position: relative;
  z-index: 1;
  border-radius: 25px;
  overflow: hidden;
  margin: 0 auto;
  background: #9b6f52;
  width: 232px;
  margin-bottom: 52px;
  margin-top: 71px;
}

fieldset#step2 {
  width: 40%;
  margin: 22px auto;
  border-radius: 7px;
  background-color: #ffffff;
  border: none;
  box-shadow: 0 5px 10px 0px rgba(0, 0, 0, 0.1);
  -moz-box-shadow: 0 5px 10px 0px rgba(0, 0, 0, 0.1);
  -webkit-box-shadow: 0 5px 10px 0px rgba(0, 0, 0, 0.1);
  -o-box-shadow: 0 5px 10px 0px rgba(0, 0, 0, 0.1);
  -ms-box-shadow: 0 5px 10px 0px rgba(0, 0, 0, 0.1);
  padding: 0;
}

.btn-name {
  width: 100%;
  height: 30px;
  cursor: pointer;
}

a {
  text-decoration: none;
}

.button-lg a {
  text-align: justify;
  color: #343534;
  font-weight: 500;
  text-transform: capitalize;
  font-size: 16px;
  margin: 0 auto;
  display: block;
  position: relative;
  left: 40%;
  word-spacing: 2px;
}

h5.appli {
  margin: 46px auto 44px;
  font-size: 19px;
  font-family: inherit;
  text-align: center;
}

#step2 i.fas.fa-circle {
  color: #f6f6f6;
  margin: 0px 0px 0px 0px;
  font-size: 29px;
  position: absolute;
  left: -41px;
  top: -4px;
  background: #f7f3f0;
  width: 30px;
  height: 30px;
  border-radius: 50%;
}

button.next.con {
  border: 1px solid #9b6f52;
  background-color: #ffffff;
  padding: 8px 30px;
  margin: 50px 0px;
  display: block;
}

#step2 hr {
  width: 282px;
  margin: 15px auto 30px;
  border-color: #f0f4f5;
}

button {
  cursor: pointer;
}

.btn-name:hover .fas {
  background: #9b6f52 !important;
}

.greytext {
  color: dimgrey;
  font-weight: 200;
  line-height: 27px;
  margin-top: 30px;
  margin-bottom: 40px;
}

.second h6 {
  margin-bottom: 6px;
  margin-top: 0px;
}

.grid-container {
  display: grid;
  grid-template-columns: auto auto auto auto auto auto auto auto auto auto auto auto auto;
}

.grid-item {
  border-bottom: 1px solid rgba(0, 0, 0, 0.8);
  margin: 4px;
  text-align: center;
  margin-bottom: 22px;
}

.grid-item.active {
  border-bottom: 2px solid #9b6f52;
}

.form.print button.submit {
  float: none;
  margin: 50px auto;
  display: block;
}

img.thumb {
  width: 100%;
  max-width: 36%;
}

img.thumb {
  -webkit-animation: mover 1s infinite alternate;
  animation: mover 1s infinite alternate;
}

.thnku {
  margin: 0 auto;
  display: block;
  text-align: center;
  background: #ffff;
  width: 620px;
  height: 100vh;
  padding: 62px 0px;
}

img.thumb {
  -webkit-animation: mover 1s infinite alternate;
  animation: mover 1s infinite alternate;
}

img.thumb {
  -webkit-animation: mover 1s infinite alternate;
  animation: mover 1s infinite alternate;
}

@-webkit-keyframes mover {
  0% {
    transform: translateY(0);
  }

  100% {
    transform: translateY(-10px);
  }
}

@keyframes mover {
  0% {
    transform: translateY(0);
  }

  100% {
    transform: translateY(-10px);
  }
}

.thnku h3 {
  text-align: center;
}

.error {
  color: red;
  position: relative;
  bottom: 22px;
  font-size: 13px;
  font-weight: 600;
}

button {
  width: 118px;
}

/* input,
textarea {
  margin-bottom: 31px !important;
}

select {
  margin-bottom: 31px !important;
} */
label {
  display: block !important;
  margin-bottom: 0.5rem !important;
}

.form h6 {
  margin-bottom: 32px;
  margin-top: 6px;
  font-weight: 600;
}

.form h3 {
  font-weight: 600;
  margin-bottom: 25px;
  font-size: 25px;
}

button {
  transition: 0.9s;
}

/* button:hover {
  background: transparent;
  color: var(--title-color) !important;
} */

.leftarrow {
  position: relative;
  top: -1px;
  right: 8px;
}

.rightarrow {
  position: relative;
  top: 0;
  left: 8px;
  transform: rotate(-92deg);
}
/* 
button:hover .rightarrow,
button:hover .leftarrow {
  filter: brightness(0.6);
} */

.fullinput input {
  width: 100%;
}

.second h6 {
  margin-bottom: 13px;
}

.button_group .btn-secondary {
  border-radius: 0px;
  border: none;
  width: 62px;
  background: #999a99;
}

.button_group .btn-primary {
  border-radius: 0px;
  width: 62px;
  background: #545b62;
  border: none;
}

.form .btn-primary:hover {
  color: #fff;
  background-color: #927562;
  border-color: #b69783;
}

button.toogles.active {
  border: 1px solid var(--title-color) 000 !important;
  background: #b69783 !important;
}

.button_group {
  display: table;
  margin-left: auto;
}

.fullinput.same {
  margin-top: 27px;
  margin-bottom: 11px;
}

.fullinput.same label {
  color: #282829;
  font-weight: 600;
}

/* .preview {
  border: 2px solid #9b9b9b;
}
.preview:hover {
  background: #9b6f52;
  border: #9b6f52;
} */
.button_group.toogleset {
  position: relative;
  bottom: 43px;
}

.form.print h4 {
  margin: 23px 0px;
}

label.btn-1 {
  position: absolute;
  bottom: 16px;
  cursor: pointer;
}

.ui.selection.dropdown {
  cursor: pointer;
  word-wrap: break-word;
  line-height: 1em;
  white-space: normal;
  outline: 0;
  transform: rotateZ(0);
  min-width: 14em;
  min-height: 2.71428571em;
  background: #fff;
  display: inline-block;
  padding: 0.78571429em 2.1em 0.78571429em 1em;
  color: rgba(0, 0, 0, 0.87);
  box-shadow: none;
  border: none;
  border-radius: 0px;
  transition: box-shadow 0.1s ease, width 0.1s ease;
  margin-bottom: 18px;
}

.selectbox .ui.search.dropdown > input.search {
  background: none #f9f9f9 !important;
  cursor: pointer;
}

.selectbox .ui.selection.dropdown {
  border: none;
}

.selectbox .col-6 {
  padding: 0;
}

.fullinput select {
  width: 100%;
  height: 42px !important;
}

.none {
  display: none;
}

.form .react-tel-input .form-control {
  /* position: relative; */
  font-size: 14px;
  letter-spacing: 0.01rem;
  margin-top: 7px !important;
  margin-bottom: 0 !important;
  padding-left: 45px;
  margin-left: 0;
  background: #f9f9f9;
  border: none;
  border-radius: 0px;
  line-height: 21px;
  height: 40px;
  width: 93%;
  outline: none;
  padding-right: 0;
}

/* .fullinput.row .col-3 {
	padding-right: 0;
}

.fullinput.row .col-6 {
	padding-left: 0;
} */
.form .ui.search.dropdown > input.search {
  background: none #f9f9f9 !important;
}

.ui.selection.dropdown {
  border: none !important;
}

.takepic {
  margin: 0 auto;
  display: table;
}

.takepics {
  display: flex;
}

.take-a-photo {
  margin-left: 32px;
}

.takepic {
  border: none;
  width: auto;
  color: #a7a09a;
  font-size: 15px;
  font-weight: 600;
}

.usual li {
  padding-bottom: 18px;
  list-style: none;
}

.mouse {
  width: 100%;
  padding-bottom: 61px;
}

.usual.space li {
  padding-bottom: 0px;
}

.containers {
  display: block;
  position: relative;
  padding-left: 35px;
  margin-bottom: 12px;
  cursor: pointer;
  font-size: 22px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.containers input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

.checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 25px;
  width: 25px;
  background-color: #939394;
}

.setSelfieImage {
  margin: 0 AUTO;
  display: BLOCK;
}

/* .containers:hover input ~ .checkmark {
  background-color: #939394;
} */

.containers input:checked ~ .checkmark {
  background-color: var(--title-color);
}

.checkmark:after {
  content: '';
  position: absolute;
  display: none;
}

.containers input:checked ~ .checkmark:after {
  display: block;
}

.containers .checkmark:after {
  left: 9px;
  top: 5px;
  width: 5px;
  height: 10px;
  border: solid white;
  border-width: 0 3px 3px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}

.checkbox-my label {
  font-size: 14px;
  color: #212529;
  font-weight: 400;
  margin-bottom: 27px !important;
}

/* .checkbox-my {
  margin-left: 12px;
} */
.checkbox-my.left {
  margin-left: 0px;
}

.full {
  width: 100%;
}

.Accountant {
  width: 152px;
  font-size: 13px;
  border: none;
  background: #999a99;
  color: #fff;
  margin-top: 22px;
}

.Accountant.active-lawyer {
  background: #b69783;
  border: 1px solid var(--title-color);
}

.copybank {
  position: relative;
  top: 33px;
}

.download {
  width: 28px;
  margin-bottom: 31px;
}

.usual.back-color {
  background: #f9f9f9;
  /* padding: 28px; */
  margin-bottom: 24px;
}

button.ok {
  display: block;
  margin: 0 auto 24px;
}

button.next.setwidht {
  width: 192px;
}

.fullspace.pandingfinsh {
  width: 100%;
}

.fullspace {
  width: 100%;
}

.form .ui.search.dropdown > input.search {
  background: none #f9f9f9 !important;
  padding: 0;
  height: 42px;
  top: 0;
  margin: 3px 0px 0px;
}

.ui.search.dropdown > .text {
  top: 5px;
}

.ui.fluid.dropdown > .dropdown.icon {
  top: 14px !important;
}

.button_group.pos {
  position: absolute;
  right: 0;
  z-index: 1;
}

.id {
  margin: 0 auto 32px;
  display: table;
}

.trans {
  background: transparent;
  font-weight: 600;
  color: dimgrey;
  padding: 0;
}

.save-exit p {
  font-weight: 600;
  color: #b4c9d0;
  float: right;
  position: absolute;
  bottom: 49px;
  font-size: 11px;
  right: 22px;
  top: 12px;
  cursor: pointer;
  height: 0;
}

button.copyall {
  border: none;
  width: auto;
  position: absolute;
  top: 20px;
  right: 0;
}

button.copyall:hover {
  background: none;
  color: #fff;
}

.valuePerUnit {
  display: contents;
}

#male {
  width: 18px;
  margin-top: 5px;
}

.containerss {
  margin: 0;
  padding: 1px;
  padding-left: 7px;
}

.setradio {
  display: flex;
}

.spinner {
  margin: 0 auto;
  display: block;
  text-align: center;
}

span.againFile {
  margin-left: 16px;
  font-size: 13px;
  color: #a7a09a;
  margin-top: 21px;
}

button.signupm {
  position: absolute;
  top: 0;
  right: 6px;
  top: 6px;
  padding: 5px;
}

button.btn.btn-toogles.active.btn.btn-primary.brown {
  background: #b69783;
  border: 1px solid black;
}

.colors {
  display: none;
}

.red {
  display: block;
}

input#maless {
  width: 31px;
  margin-top: 5px;
}

input#males {
  width: 54px;
  margin-top: 5px;
}

.setleft {
  position: relative;
  left: 33px;
  margin-bottom: 17px;
}

.switch-field {
  display: flex;
  margin-bottom: 36px;
  overflow: hidden;
  margin: 0 auto;
}

.switch-field input {
  position: absolute !important;
  clip: rect(0, 0, 0, 0);
  height: 1px;
  width: 1px;
  border: 0;
  overflow: hidden;
}

.switch-field label {
  background-color: #999a99;
  color: rgb(255 255 255);
  font-size: 14px;
  line-height: 1;
  text-align: center;
  padding: 8px 16px;
  margin-right: -1px;
  border: none;
  box-shadow: none;
  transition: all 0.1s ease-in-out;
  font-size: 14px;
  margin: 2px;
  border-radius: 0;
  font-weight: 300;
}

.switch-field label:hover {
  cursor: pointer;
}

.switch-field.nospace label.toogles {
  margin: 0;
}

.switch-field input:checked + label {
  background-color: #b69783;
  box-shadow: none;
  border: 1px solid black;
}

.button_group.space-bottom .switch-field label {
  margin: 0px;
}

.switch-field label:first-of-type {
  border-radius: 0px 0 0 0x;
}

.switch-field label:last-of-type {
  border-radius: 0 0px 0px 0;
}

label.toogles.actives {
  background-color: #b69783;
  box-shadow: none;
  border: 1px solid black;
}

/* This is just for CodePen. */

div#hidemy {
  display: none;
}

div#showmy {
  display: none;
}

.flag-select__option {
  cursor: pointer;
  padding: 0 !important;
  margin: 0 !important;
  white-space: nowrap;
}

button#select_flag_button {
  width: 93%;
  padding: 0;
  font-size: 15px !important;
}

.flag-select {
  position: relative;
  display: inline-block;
  vertical-align: inherit;
  padding-bottom: 0 !important;
  text-align: left;
  width: 100%;
  background: #f9f9f9;
}

button#select_flag_button:hover {
  background: transparent;
  color: var(--title-color);
}

.flag-select__option__icon {
  width: 1.3em;
  height: 1.3em;
  position: relative;
  top: -2px !important;
}

button:focus {
  outline: 1px dotted;
  outline: none !important;
}

.flag-select {
  margin-bottom: 15px;
  margin-top: 3px;
  padding: 4px 0px;
}

/* The container */
.checkmystyle {
  display: block;
  position: relative;
  padding-left: 35px;
  margin-bottom: 12px;
  cursor: pointer;
  font-size: 22px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

/* Hide the browser's default radio button */
.checkmystyle input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
}

/* Create a custom radio button */
.checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 25px;
  width: 25px;
  background-color: #fff;
  border-radius: 0%;
  border: 1px solid var(--title-color) 000;
}

/* On mouse-over, add a grey background color */
.checkmystyle:hover input ~ .checkmark {
  background-color: #ccc;
}

/* When the radio button is checked, add a blue background */
.checkmystyle input:checked ~ .checkmark {
  background-color: #b69783;
}

/* Create the indicator (the dot/circle - hidden when not checked) */
.checkmark:after {
  content: '';
  position: absolute;
  display: none;
}

/* Show the indicator (dot/circle) when checked */
.checkmystyle input:checked ~ .checkmark:after {
  display: block;
}

/* Style the indicator (dot/circle) */
.checkmystyle .checkmark:after {
  left: 9px;
  top: 5px;
  width: 5px;
  height: 10px;
  border: solid white;
  border-width: 0 3px 3px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}

span.styletest {
  margin-left: 34px;
}

.flag-select__btn:after {
  position: absolute;
  right: 8px;
  top: 16px;
}

.flag-select__options {
  width: 100%;
}

#printDate {
  margin-left: 4px;
}

canvas {
  background: #f9f9f9;
  border: 1px solid #cbcaca;
}

button.btn.btn-default.button.clear {
  background: #727272;
  color: #fff;
  margin: 22px 0px 0px;
}

.Other.box {
  display: none;
}

.None.box {
  display: none;
}

.btn_upload {
  cursor: pointer;
}

.btn_upload:hover,
.btn_upload:focus {
  background-color: #7ca9e6;
}

.yes {
  display: flex;
  align-items: flex-start;
  margin-top: 10px !important;
}

.btn_upload input {
  cursor: pointer;
  height: 100%;
  position: absolute;
  filter: alpha(opacity=1);
  -moz-opacity: 0;
  opacity: 0;
}

.it {
  /* height: 100px; */
  margin-left: 0px;
  font-size: 13px;
  color: #a7a09a;
}

input#removeImage2 {
  background: #6e6e6e;
  padding: 0px 0px 0px 6px;
  margin-left: 4px;
  margin-top: -2px;
}
input#removeImage2_note {
  background: #6e6e6e;
  padding: 0px 0px 0px 6px;
  margin-left: 4px;
  margin-top: -2px;
}
.btn-rmv1,
.btn-rmv2,
.btn-rmv2_note,
.btn-rmv2_note0,
.btn-rmv3,
.btn-rmv4,
.btn-rmv5 {
  display: none;
}

.rmv {
  cursor: pointer;
  color: #fff;
  border-radius: 0px;
  border: 1px solid #fff;
  display: inline-block;
  background: rgba(255, 0, 0, 1);
  margin: 0px;
  position: absolute;
}

.rmv:hover {
  background: rgba(255, 0, 0, 0.5);
}

#ImgPreview2,
#ImgPreview2_note {
  margin-top: 22px;
  width: 20%;
  height: auto;
}

#ImgPreview2 {
  margin-top: 22px;
  width: 20%;
  height: auto;
  margin-bottom: 42px;
  margin-left: 0;
}

/* .rmv {
  width: 23px;
  padding: 2px 10px 2px 6px;
  background: #b69783;
} */

.spceimg {
  margin-top: 10px;
}

.ml-50 {
  margin-left: 50px;
}

ul.flag-select__options {
  padding: 5px;
  line-height: 28px;
}

.signupForm {
  padding: 10px;
  background: #ffffff;
  color: #a7a09a;
  border: 2px solid #a7a09a;
  transform: translateX(0);
  transition: all 0.3s ease;
  margin: 20px 0px;
}

.signupFormPaw {
  background: url('http://pawtopia.graphicdesignsd.com/wp-content/themes/Pawtopia15/images/footer-icon.png')
    no-repeat;
  width: 42px;
  height: 9px;
  margin: auto;
  position: relative;
  top: -35px;
}

.signupButton:hover {
  background-color: #ddd;
  color: #444;
  text-decoration: none;
}

.signupXButton {
  position: absolute;
  top: -2px;
  right: -2px;
  background-color: var(--title-color);
  color: #fff;
  background-image: none;
  font-size: 11px;
  font-weight: 700;
  padding: 6px 9px;
  text-align: center;
  text-transform: uppercase;
  letter-spacing: 1px;
  /* border-radius: 3px; */
  border: 0;
  line-height: normal;
  cursor: pointer;
  box-shadow: none;
  text-decoration: none;
}

.signupButton {
  cursor: pointer;
}

.signupForm b {
  font-weight: 300;
  line-height: 23px;
}

.block #hidemy {
  display: block;
}

.fullinput.row .col-11 label {
  margin-top: 14px;
}

.fullspace.pandingfinsh .col-11 label {
  margin-top: 14px;
}

a {
  color: var(--title-color) !important;
}

.yesnoNameerror {
  position: relative;
  width: 100%;
  bottom: 0;
}

input.numbers.setinput {
  margin-top: 7px;
}

.display {
  display: flex;
}

a.hyperLink1 {
  margin-top: -2px;
  margin-left: 10px;
  color: #7a7979 !important;
  font-weight: 600;
}

.ui.compact.icon.button,
.ui.compact.icon.buttons .button {
  padding: 6px 2px;
  width: 24px;
}

.clndr-cell-disabled {
  cursor: default;
  background: #9b6f52 !important;
  color: #fff !important;
  opacity: 1 !important;
  text-align: center;
}

[role='button'] {
  text-align: center;
}

.ui.icon.input > i.icon:after,
.ui.icon.input > i.icon:before {
  top: 28% !important;
}

.ui.icon.input > input {
  background: #f9f9f9 !important;
  border: none !important;
  border-radius: 0 !important;
}

i.icon.angle.double.right:before {
  margin-right: 6px;
}

i.icon.angle.double.left:before {
  margin-right: 22px;
}

.clndr-calendars-segment.clndr-floating {
  top: 35px;
}

.form select {
  font-weight: 200;
}

.button_group.space-bottom .step-change.active {
  background-color: #b69783 !important;
  box-shadow: none;
  border: 1px solid black !important;
}

.step-change {
  background: #999a99 !important;
  border: none !important;
}

.step-change.actives {
  background-color: #b69783 !important;
  box-shadow: none !important;
  border: 1px solid black !important;
}

.ui.icon.input > input {
  width: 537px;
}

/* video{
  display: none
} */
.colors .col-11 label {
  margin-top: 14px;
}

span.error.file {
  bottom: 0;
  margin-left: 14px;
}

/*PRELOADING------------ */
#overlayer {
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: 1;
  background: #f7f3f0;
}

.loader {
  display: inline-block;
  width: 30px;
  height: 30px;
  position: absolute;
  z-index: 3;
  border: 4px solid var(--title-color);
  top: 50%;
  animation: loader 2s infinite ease;
}

.loader-inner {
  vertical-align: top;
  display: inline-block;
  width: 100%;
  animation: loader-inner 2s infinite ease-in;
}
@keyframes loader {
  0% {
    transform: rotate(0deg);
  }

  25% {
    transform: rotate(180deg);
  }

  50% {
    transform: rotate(180deg);
  }

  75% {
    transform: rotate(360deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

@keyframes loader-inner {
  0% {
    height: 0%;
  }

  25% {
    height: 0%;
  }

  50% {
    height: 100%;
  }

  75% {
    height: 100%;
  }

  100% {
    height: 0%;
  }
}

span.loader {
  display: block !important;
  margin: 0 auto;
  text-align: center;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

/* Preloader */

#preloader {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #fff;
  /* change if the mask should have another color then white */
  z-index: 99;
  /* makes sure it stays on top */
}

#status {
  width: 200px;
  height: 200px;
  position: absolute;
  left: 50%;
  /* centers the loading animation horizontally one the screen */
  top: 50%;
  /* centers the loading animation vertically one the screen */
  background-image: url(https://raw.githubusercontent.com/niklausgerber/PreLoadMe/master/img/status.gif);
  /* path to your loading animation */
  background-repeat: no-repeat;
  background-position: center;
  margin: -100px 0 0 -100px;
  /* is width and height divided by two */
}

.numberSet.col-3 {
  flex: 0 0 25%;
  max-width: 20%;
}

.numberSets.col-6 {
  flex: 0 0 60%;
  max-width: 55%;
}

.ui.icon.input > i.icon {
  top: 18px !important;
  height: auto !important;
}

.swal-icon--success__line--tip {
  width: 25px;
  left: 4px;
  top: 47px;
}

.swal-button {
  border: 1px solid #202020 !important;
  color: #fff !important;
  background-color: #202020 !important;
  box-shadow: none !important;
  border-radius: 5px !important;
  cursor: pointer !important;
  background-color: transparent;
}

.swal-button:focus {
  outline: none !important;
  box-shadow: none !important;
}

.swal-button:not([disabled]):hover {
  background-color: #fff !important;
  color: #202020 !important;
}

.swal-text {
  color: #202020;
  /* font-weight: 100 !important; */
  font-size: 16px;
  font-family: revert;
}

#terms {
  height: 200px;
  overflow-y: scroll;
}

#terms {
  height: 200px;
  overflow-y: scroll;
  padding: 0px 13px;
}

#termss {
  height: 200px;
  overflow-y: scroll;
  padding: 0px 13px;
}

.btn-primary:not(:disabled):not(.disabled).active,
.btn-primary:not(:disabled):not(.disabled):active,
.show > .btn-primary.dropdown-toggle {
  color: #fff !important;
  background-color: var(--title-color) !important;
  /* border-color: var(--title-color)000; */
  /* border: 1px solid black !important; */
  border-radius: 30px;
}

input.toogles.postalAddressButton.btn.btn-secondary.active {
  color: #fff !important;
  background-color: var(--title-color) !important;
  /* border-color: var(--title-color)000; */
  /* border: 1px solid black !important; */
  border-radius: 30px;
}

.mmyset {
  position: relative;
}

.info.myset {
  position: absolute;
  top: 5px;
  left: 52%;
  cursor: pointer;
}

.deisablecrouse {
  cursor: not-allowed;
}

.error.postalError {
  bottom: 4px;
}

.ui.disabled.input,
.ui.input input[disabled] {
  opacity: 1.45;
}

span.error.countryOfResidence {
  bottom: 6px;
}

div#result {
  color: red;
  position: relative;
  bottom: 12px;
}

span.error.takepics {
  text-align: center;
  left: 38%;
  bottom: 4px;
}

.info {
  float: right;
  position: absolute;
  bottom: 49px;
  font-size: 11px;
  right: 5px;
  top: 14px;
  cursor: pointer;
}

#root
  > div
  > div
  > div
  > div
  > div:nth-child(10)
  > div:nth-child(2)
  > div
  > div.flag-dropdown.open
  > ul
  > li:nth-child(88)
  span.country-name {
  position: relative !important;
  top: -32px !important;
}

#root
  > div
  > div
  > div
  > div
  > div:nth-child(10)
  > div:nth-child(2)
  > div
  > div.flag-dropdown.open
  > ul
  > li:nth-child(88)
  span.dial-code {
  position: relative !important;
  top: -32px !important;
}

#root
  > div
  > div
  > div
  > div
  > div:nth-child(10)
  > div:nth-child(2)
  > div
  > div.flag-dropdown.open
  > ul
  > li:nth-child(88)
  li.country {
  height: 30px !important;
}

span.country-name {
  font-size: 12px;
}

span.dial-code {
  font-size: 12px;
}

.error-list {
  color: rgb(111 111 111);
  font-weight: 100 !important;
  font-size: 14px;
  font-family: revert;
  padding: 0;
}

.error-list a {
  color: rgb(111 111 111) !important;
  font-weight: 100 !important;
  font-size: 14px;
  font-family: revert;
}

.button-lg.main-page nav.navbar.navbar-expand-md.navbar-light {
  background: initial !important;
  position: initial !important;
}

.button-lg.main-page ul.mr-auto.navbar-nav {
  display: none;
}

.button-lg.main-page nav.navbar.navbar-expand-md.navbar-light.bg-light {
  padding: 0;
  width: auto;
}

.button-lg.main-page .btn-primary {
  color: #0b0909;
  background-color: initial;
  border-color: initial;
  border: 0;
  padding: 0;
  text-align: left;
}

.checkbox-my.left-more {
  margin-left: 42px !important;
}

span.error.setSelfieImage {
  text-align: center;
  bottom: -8px;
}

.css-yk16xz-control {
  background: #f9f9f9 !important;
  height: 40px !important;
  padding: 0 !important;
  margin-bottom: 42px !important;
  border: none;
}

.css-1wa3eu0-placeholder {
  top: 18px !important;
}

.css-tlfecz-indicatorContainer {
  display: none !important;
}

span.css-1okebmr-indicatorSeparator {
  display: none !important;
}

.css-yk16xz-control {
  border: none !important;
}

.css-1uccc91-singleValue {
  top: 30% !important;
}

.css-1uccc91-singleValue {
  top: 19px;
}

.css-1pahdxg-control {
  height: 0 !important;
  margin-bottom: 42px;
}

svg.css-6q0nyr-Svg {
  display: none;
}

button#select_flag_button {
  overflow: hidden;
}

/* path {
  display: none !important;
} */
.m-signature-pad--body canvas {
  width: 100%;
}

span.error.takePhoteError {
  bottom: -7px;
  position: relative;
  text-align: center;
  margin: 0 auto;
  display: block;
}

span.error.uploadPhoteError {
  bottom: 0;
}

.grid-item {
  width: 51px;
}

li.country .flag.id {
  margin: 0px auto;
  margin-right: 7px;
}

.fullspace.pandingfinsh.block.setpadding {
  padding: 15px;
}

img.width-set {
  width: 540px;
  max-width: 100%;
}

input[type='radio'],
input[type='checkbox'] {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  padding: 0;
  width: 222px;
}

input#radio-one2 {
  position: absolute;
}

.switch-fieldd.nospacee {
  display: flex;
  align-items: center;
}

.switch-fieldd.nospacee label {
  color: #fff;
  padding: 5px;
  font-size: 14px;
  border-radius: 0;
  font-weight: 300;
}

.button_group.space-bottom .step-changes.active {
  background-color: #b69783 !important;
  box-shadow: none;
  border: 1px solid black !important;
}

input#radio-three {
  z-index: 999949;
  position: absolute;
  opacity: 0;
  left: -1px;
  width: 187px;
  margin-left: 0;
  height: 52px;
}

input#radio-four {
  z-index: 999949;
  position: absolute;
  opacity: 0;
  left: -1px;
  width: 187px;
  margin-left: 0px;
  height: 52px;
}

.switch-fieldd.nospacee .step-change {
  width: 102px;
  cursor: pointer;
}

.switch-fieldd .step-change {
  color: #fff;
  padding: 11px;
  font-size: 14px;
  font-weight: 400;
  text-align: center;
  width: 162px;
  cursor: pointer;
}

.spcewidths .switch-fieldd .step-change {
  width: 262px;
  cursor: pointer;
}

.red-dom .joint-acc {
  z-index: 999999;
  position: absolute;
  opacity: 0;
  width: 100%;
  margin: 0;
  height: 100%;
  cursor: pointer;
}

.red-dom {
  position: relative;
}

.spcewidths label.step-change {
  color: #fff;
  text-align: center;
  font-size: 14px;
  font-weight: 300;
  padding: 9px;
}

.red-dom label.step-change {
  margin-bottom: 0 !important;
}

.spcewidths .step-change.active {
  background: #9b6f52 !important;
  border: 1px solid var(--title-color) !important;
}

.switch-fieldd.nospacee.wids .step-change {
  width: 71px;
  cursor: pointer;
}

.switch-fieldd.nospacee.wids .step-change.active {
  background: #b69783 !important;
  border: 1px solid var(--title-color) !important;
}

/* input[type="text"] {
  color: #a2a2a2 !important;
} */
input:-internal-autofill-selected {
  appearance: menulist-button;
  background-color: #f9f9f9 !important;
  background-image: none !important;
  color: -internal-light-dark(#a2a2a2, white) !important;
  color: #a2a2a2 !important;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  /* -webkit-text-fill-color: #a2a2a2 !important; */
  box-shadow: 0 0 0px 1000px #f9f9f9 inset;
}

.ui.icon.input > input:-webkit-autofill,
.ui.icon.input > input:-webkit-autofill:hover,
.ui.icon.input > input:-webkit-autofill:focus,
.ui.icon.input > input:-webkit-autofill:active {
  /* -webkit-text-fill-color: #a2a2a2 !important; */
  box-shadow: 0 0 0px 1000px #f9f9f9 inset;
}

input {
  color: var(--title-color) !important;
}

.button_group.irdspace input {
  color: var(--title-color) !important;
}

.mb-custom {
  margin-bottom: 0 !important;
}

.dr-zero {
  float: right;
}

.button_group.irdspace input.toogles {
  width: auto;
  padding: 8px 16px;
  line-height: 1.2;
  background: transparent;
  border-radius: 30px !important;
}

.rmv {
  color: #fff !important;
}

.position-na {
  bottom: 0;
}

.error.countryOfBirth {
  bottom: 10px;
}

.error.postals {
  bottom: 0;
}

.infos {
  top: -5px;
  position: relative;
}

ul.d-none.d-md-block.navbar-nav {
  position: absolute;
  top: 14px;
  z-index: 1;
}

.checkbox-my span.error {
  margin-left: 36px;
}

input.mouse.otherInput {
  margin-bottom: 17px !important;
  margin-top: -8px;
}

span.error.investmentProductExpOther {
  margin-left: 0;
  bottom: 8px;
}

.pl-34 {
  padding-left: 34px;
}

.fotter-btn.btn-right {
  margin-left: auto !important;
  display: table !important;
}

ul.welcome-space li {
  margin-bottom: 0.5rem;
}

span.error.postalad {
  bottom: -3px;
}

.width-set .red-dom label {
  width: 55px !important;
}

.spceimg input {
  width: 23px;
}

.space-btn {
  display: flex;
}

.space-btn label {
  width: 154px !important;
  margin: 5px !important;
  padding: 10px;
}

.ful_width input {
  width: 100% !important;
}

.btn-set1 {
  width: auto;
  position: relative;
  top: -12px;
  height: auto;
  background: #b69783;
}

.btn-set1:after {
  width: 100%;
  position: absolute;
  content: '';
  background: url(../src/pages/Delete.png);
  height: 100%;
  display: block;
  top: 0;
  left: 0;
}

.pikercss {
  margin-top: 7px;
  height: 73px;
}

.trustee_pikercss {
  margin-top: -17px;
  height: 48px;
}

.trustee_pikercss label {
  padding: 12px;
}

button.btncolor,
label.toogles.actives.comp_dir_btn {
  background: #999a99;
  color: #fff;
  border: none;
  width: 166px;
  padding: 0.5rem 5px;
  border: 1px solid #999a99;
}

label.toogles.actives.comp_dir_btn {
  padding: 0.7rem 5px;
}

button.btncolor:hover,
label.toogles.actives.comp_dir_btn:hover {
  background: #9b6f52;
  color: #fff;
  border: 1px solid var(--title-color);
}

/* .check_YN.check_b input.toogles.active.btn.btn-primary{
  background-color: var(--title-color)000 !important;
  color: #ffffff !important;
} */
.custom-margin {
  margin-left: 48px;
  margin-top: 0;
}

.b-0 {
  bottom: 0;
}

.b-5 {
  bottom: 5px;
}

.error_text {
  bottom: 0;
  text-align: center;
  display: block;
}

.swal-content__div {
  color: rgb(111 111 111);
  font-weight: 100 !important;
  font-size: 14px;
}

.minusten {
  margin-top: -7px;
}

.width-100 label {
  width: 100%;
}

.switch-field.margin-btn label {
  margin: 0;
}

.new-position {
  position: absolute;
  bottom: 5px;
}

.pos-under {
  position: absolute;
  left: 15px;
  top: 56px;
}

.onlyshare.row:nth-child(odd) {
  display: none;
}

.deleteshow {
  display: none;
}

.fullinput.onlyshare.row.main {
  display: flex;
}

.onlydirector.row:nth-child(odd) {
  display: none;
}

.deletedirector {
  display: none;
}

.fullinput.onlydirector.row.main {
  display: flex;
}

label.heading-margin {
  margin-bottom: 32px !important;
  margin-top: 6px;
}

:is(button.coffee-colortwo, label.toogles.actives.comp_dir_del_btn) img {
  max-width: 20px;
}

button.coffee-colortwo,
label.toogles.actives.comp_dir_del_btn {
  background: #b69783;
  width: 47px;
  height: 42px;
  padding: 0px;
  border: 2px solid #c7b0a0;
}

label.toogles.actives.comp_dir_del_btn {
  padding: 8px;
}

button.next.setwidht.width_oneeighteen {
  width: 118px;
}

.policy_font {
  font-size: 16px;
}

.t-55 {
  top: 55px;
}

.d-none.hideFieldd {
  display: flex !important;
}

i.ICON__Arrow {
  background: var(--title-color);
  color: #fff;
  display: inline-block;
  /* padding: 13px; */
  border: 2px solid var(--title-color);
  border-radius: 5px;

  vertical-align: middle;

  text-align: center;
  padding: 6px 0;
  margin-right: 14px;
}

button {
  width: auto !important;
}
@media screen and (min-width: 786px) {
  .m-signature-pad--body canvas {
    width: 100%;
  }

  video {
    width: 100% !important;
  }
}

.hideField {
  display: none;
}

@media screen and (min-width: 1400px) {
  .usual.back-color.terms_cond {
    height: 199.9px !important;
  }
}

img.MuiBox-root.css-1ngw63v {
  display: none;
}

.MuiToolbar-root.MuiToolbar-gutters.MuiToolbar-regular.css-47ifef-MuiToolbar-root {
  border-bottom: 1px solid #f4f6f8;
}

.main-loader {
  display: flex;
  justify-content: center;
}

.loader {
  border: 8px solid var(--title-bg_color);
  border-radius: 50%;
  border-top: 8px solid var(--border-color);
  width: 100px;
  height: 100px;
  -webkit-animation: spin 2s linear infinite;
  /* Safari */
  animation: spin 2s linear infinite;
}

/* Safari */
@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

a.anchorfont {
  font-size: 13px;
  color: #007bff !important;
}

.toggle-switch-inner:before {
  width: 37px;
  background-color: var(--title-color) 000;
  height: 37px;
  border-radius: 50%;
}

.toggle-switch-label {
  position: relative;
}

.toggle-switch-switch {
  display: none;
}

select.colorgrey {
  color: #b4b4b4 !important;
}

.progress {
  width: 100%;
  max-width: 260px;
  height: 6px;
  background: #e1e4e8;
  border-radius: 3px;
  overflow: hidden;
  margin: 12px 0;
}

.progress .progress-bar {
  display: block;
  height: 100%;
  background: linear-gradient(
    90deg,
    #555248,
    #757073 17%,
    #100d13 34%,
    #171e1e 51%,
    #d6d6cd 68%,
    #ffffff 69%,
    #665e5e
  );
  background-size: 300% 100%;
  animation: progress-animation 2s linear infinite;
}

@keyframes progress-animation {
  0% {
    background-position: 100%;
  }

  100% {
    background-position: 0;
  }
}

.red {
  color: red;
}

.iconstyle {
  margin-left: -12px;
  margin-right: 8px;
}

.downloads {
  width: 30px;
  height: 30px;
  background: var(--title-color);
}

.downloadeStyle {
  color: #fff;
  text-align: center;
  margin-left: 9px;
  margin-top: 7px;
  font-size: 13px;
}

.licedocument-downloade {
  display: flex;
  width: 100%;
}

.main-downloade-wrapper {
  display: flex;
  width: 100%;
}

.downloadeMsg {
  margin-left: 10px;
  color: var(--title-color);
  font-size: 14px;
  font-weight: 500;
  text-transform: uppercase;
  margin-top: 4px;
  margin-left: 15px;
}

/* new changes 11-12 css */
.alignment {
  margin-top: 6px;
  margin-left: 26px;
}

.leftalignment {
  margin-left: 25px;
}

.listalignment {
  padding-left: 5px;
}

li.orderListAlignment {
  padding-left: 10px;
  margin-bottom: 5px;
}

.spacetop {
  margin-top: 5px;
}

p {
  font-family: 'Roboto', sans-serif !important;
  /* font-size: 14px !important; */
}

form {
  font-family: 'Roboto', sans-serif !important;
}

li {
  font-family: 'Roboto', sans-serif !important;
}

/* new changes */
.btn-box.border_BTN {
  margin-bottom: 10px;
}

.circle-box .avatarname {
  width: 85px;
  background: #d8d8d8;
  height: 85px;
  border-radius: 50%;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
  justify-content: center;
  -webkit-align-items: center;
  align-items: center;
  margin: auto;
}

.circle-box .avatarname span {
  font-size: 40px !important;
  font-weight: 500 !important;
  text-align: center;
}

.hh {
  margin-left: 30px !important;
  /* margin-bottom: 50px; */
}

.tt {
  margin-left: 25px;
}

/* new css */
.left_Menus {
  width: 280px;
  padding: 30px 20px;
  background: #f1eeeb;
  height: calc(100vh - 110px);
  position: fixed;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.left_Menus .logout__bTN button {
  background-color: var(--title-color);
  color: #fff !important;
  border: 1px solid var(--title-color);
  justify-content: center;
}

.left_Menus .logout__bTN button:hover {
  background-color: #fff;
  color: var(--title-color) !important;
}

.left_Menus button {
  background: #fff;
  color: var(--title-color) !important;
  font-size: 17px !important;
  padding: 10px 15px;
  width: 100% !important;
  display: flex;
  margin: 6px 0;
  background-color: #f1eeeb;
  border-radius: 8px;
  position: relative;
  font-weight: 400 !important;
  border: none;
  text-transform: capitalize !important;
}

.left_Menus button i {
  font-weight: 500;
  padding-right: 10px;
  font-size: 18px;
}

.left_Menus button:hover {
  background: var(--title-color);
  color: #fff !important;
}

.left_Menus button.active {
  background: var(--title-color);
  color: #fff !important;
}

.content__box__main.css-1byr0tz {
  display: flex;
  padding-top: 111px;
  padding-left: 0;
  padding-right: 0;
  /* position: fixed; */
  position: relative;
  width: 100%;
  padding-bottom: 0;
}

.main_container {
  width: calc(100% - 280px);
  /* position: absolute;
  right: 0; */
  background-color: #d5d5d5;
  margin-left: 280px;
}

.nav_logo.circle-box + p {
  font-weight: 700;
  text-align: center;
  margin-top: 30px;
  font-size: 18px;
}

.left_Menus button svg {
  height: 28px;
  font-size: 28px;
}

.header_TP button:hover {
  color: #fff !important;
}

.main-loader {
  background: #fff;
  float: left;
  height: 100%;
  width: 100%;
  top: 0;
  position: absolute;
}

.main-loader .loader {
  position: absolute;
  left: 0;
  right: 0;
  margin: auto;
}

.maisdn > button {
  display: none;
}
@media screen and(max-width:1200px) {
  .posta {
    flex-direction: column;
    align-items: flex-start;
  }
}

@media screen and (max-width: 1199px) {
  .main_container {
    /* width: calc(100% - 320px); */
    /* position: absolute;
    right: 20px; */
    /* background-color: #D5D5D5;
    padding-right: 20px; */
    padding-right: 20px;
  }
}

@media screen and (max-width: 991px) {
  .main_container {
    width: calc(100% - 30px);
    position: absolute;
    right: 0;
    background-color: #d5d5d5;
    left: 0;
    margin: auto;
    padding-right: 0;
  }

  .form {
    max-width: 100%;
    margin-left: 0;
  }

  /* .left_Menus {
    display: none;
  } */
  .maisdn > button {
    display: block;
  }

  .maisdn > button {
    position: absolute;
    z-index: 9;
    display: flex;
    align-items: center;
    justify-content: center;
    top: 5px;
    left: 5px;
    height: 36px;
    width: 36px !important;
    padding: 0 !important;
    font-size: 22px !important;
  }

  .left_Menus {
    left: -280px;
    transition: 0.4s;
    opacity: 0;
  }

  .is_active .left_Menus {
    left: 0;
    opacity: 1;
  }

  .is_active .left_Menus:after {
    position: absolute;
    content: '';
    left: 100%;
    width: 1200px;
    height: 100%;
    z-index: 9999;
    top: 0;
    background: rgba(4, 4, 4, 0.3);
  }

  .maisdn.is_active .fa-bars {
    display: none;
  }

  .maisdn .fa-times {
    display: none;
  }

  .maisdn.is_active .fa-times {
    display: block;
  }
}

pre,
span {
  font-family: 'Roboto', sans-serif !important;
  font-size: 14px;
}

.subscription {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: #d5d5d5;
  padding: 10px 20px;
  border: 2px solid #d5d5d5;
  border-radius: 10px;
}

.left-content {
  flex: 1;
  font-weight: 600;
}

.center-content {
  flex: 2;
  font-weight: 600;
}

.right-content {
  flex: 1;
  font-weight: 600;
}

.table-box,
.second-main-wrapper {
  background: #d5d5d5;
  padding: 10px 20px;
  border: 2px solid #d5d5d5;
  border-radius: 10px;
  margin-bottom: 25px;
}

.table-term-condition,
td {
  font-weight: 600;
  padding: 5px;
}

.widthTD {
  width: 10%;
  padding: 5px !important;
}

.widthTD-2 {
  width: calc(100% - 70%);
  padding: 5px !important;
}

.widthTD-3 {
  width: 20%;
  padding: 5px !important;
}

.ExecutedAgg {
  letter-spacing: 1px;
}

table.second-part-content-table {
  width: 100%;
  text-align: center;
}

table.second-part-content-table td {
  text-align: center;
}

table.second-part-content-table select.colorblack {
  width: 132px;
}

td.term-entity {
  width: 40%;
  text-align: center;
}

table.behalf-sign {
  margin-bottom: 20px;
}

.main-second-text-content {
  margin-bottom: 10px;
}

.ORDER-Content {
  padding-left: 10px;
  padding-inline-start: 22px;
}

.ORDER-Content li {
  padding-left: 10px;
  margin-bottom: 10px;
}

.list-header {
  font-weight: 600;
  padding: 10px 5px;
}

.main-second-text-content ol {
  list-style-type: none;
  counter-reset: item;
  margin: 0;
  padding: 0;
}

.main-second-text-content li {
  display: table;
  counter-increment: item;
  margin-bottom: 0.6em;
}

.main-second-text-content li:before {
  content: counters(item, '.');
  display: table-cell;
  padding-right: 0.6em;
}

.main-second-text-content li li {
  margin: 0;
  margin-bottom: 10px;
}

.main-second-text-content li li:before {
  content: counters(item, '.') ' ';
}

.main-second-text-content li.parent:before {
  content: '';
}

.list-Alphabet {
  list-style-type: lower-alpha;
}

.main-wrapper-term {
  display: flex;
  justify-content: space-between;
  margin-bottom: 15px;
}

.maisdn {
  position: absolute;
  z-index: 9;
}

/* new close  css */
pre,
span {
  font-family: 'Roboto', sans-serif !important;
  font-size: 14px;
}

.subscription {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: #d5d5d5;
  padding: 10px 20px;
  border: 2px solid #d5d5d5;
  border-radius: 10px;
}

.left-content {
  flex: 1;
  font-weight: 600;
}

.center-content {
  flex: 2;
  font-weight: 600;
}

.right-content {
  flex: 1;
  font-weight: 600;
}

.table-box,
.second-main-wrapper {
  background: #d5d5d5;
  padding: 10px 20px;
  border: 2px solid #d5d5d5;
  border-radius: 10px;
  margin-bottom: 25px;
}

.table-term-condition,
td {
  font-weight: 600;
  padding: 5px;
}

.widthTD {
  width: 10%;
  padding: 5px !important;
}

.widthTD-2 {
  width: calc(100% - 70%);
  padding: 5px !important;
}

.widthTD-3 {
  width: 20%;
  padding: 5px !important;
}

.ExecutedAgg {
  letter-spacing: 1px;
}

table.second-part-content-table {
  width: 100%;
  text-align: center;
}

table.second-part-content-table td {
  text-align: center;
}

table.second-part-content-table select.colorblack {
  width: 132px;
}

td.term-entity {
  width: 40%;
  text-align: center;
}

table.behalf-sign {
  margin-bottom: 20px;
}

.main-second-text-content {
  margin-bottom: 10px;
}

.ORDER-Content {
  padding-left: 10px;
  padding-inline-start: 22px;
}

.ORDER-Content li {
  padding-left: 10px;
  margin-bottom: 10px;
}

.list-header {
  font-weight: 600;
  padding: 10px 5px;
}

.main-second-text-content ol {
  list-style-type: none;
  counter-reset: item;
  margin: 0;
  padding: 0;
}

.main-second-text-content li {
  display: table;
  counter-increment: item;
  margin-bottom: 0.6em;
}

.main-second-text-content li:before {
  content: counters(item, '.');
  display: table-cell;
  padding-right: 0.6em;
}

.main-second-text-content li li {
  margin: 0;
  margin-bottom: 10px;
}

.main-second-text-content li li:before {
  content: counters(item, '.') ' ';
}

.main-second-text-content li.parent:before {
  content: '';
}

.list-Alphabet {
  list-style-type: lower-alpha;
}

.main-wrapper-term {
  display: flex;
  justify-content: space-between;
  margin-bottom: 15px;
}

.font-bold {
  font-weight: 700;
}

.padding-left-term {
  padding-left: 5px;
}

.space-bottom-wrapper {
  margin-left: 33px;
}

.bold-bottom-space {
  margin-bottom: 5px;
  display: block;
}

.bold-Partners {
  font-weight: 700;
}

.wrapper-Partners {
  margin-left: 25px;
}

td.term-Signed {
  text-transform: capitalize;
}

.second-part-content-table {
  text-transform: capitalize;
}

table.second-part-content-table select.colorblack {
  width: 140px;
  margin: auto;
  margin-bottom: 0 !important;
}

.logoBlack {
  width: 200px;
}
.view_tabs .nav-pills .nav-link:hover {
  background-color: #d5d5d5;
  color: var(--title-color);
}

.Blackbg-logo {
  background-color: var(--title-color);
  padding: 20px;
  border-radius: 9px;
  border: 2px solid var(--title-color);
}

.logo-Blackbg {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}

.onpage {
  color: #fff;
}

.main-FundNAME-AGREEMENT {
  color: #fff;
}

.FundNAME-AGREEMENT {
  display: flex;
  justify-content: space-between;
}

.FundNAME-AGREEMENT .ADHERENCE {
  margin-bottom: 0px;
}

.logo-Blackbg .TermsLOgo {
  width: 200px;
}

.Seconf-part-AGREEMENT {
  background: #d5d5d5;
  padding: 10px 15px;
  margin-bottom: 70px;
}

.outer-style-AGREEMENT {
  background: #fff;
  border: 2px solid #fff;
  border-radius: 10px;
  padding: 5px 7px;
  height: 60px;
}

.main-wrapper-AGREEMENT {
  display: flex;
  align-items: center;
}

.FundNAME-AGREEMENT .input-date label {
  color: #fff;
}

.main-wrapper-AGREEMENT {
  display: flex;
  align-items: center;
}

.AGREEMENT-left {
  width: 70px;
  margin-right: 10px;
}

.AGREEMENT-center {
  width: calc(100% - 280px);
  margin-right: 10px;
}

.AGREEMENT-center-span {
  width: calc(100% - 280px);
}

.right-AGREEMENT {
  width: 179px;
}

.outter-AGREEMENT {
  margin: 10px;
}

.PARTNER-info {
  width: 100%;
}

.PARTNER-info .PARTNER-info-content {
  border: 2px solid var(--title-color);
  width: 234px;
  max-width: 500px;
  padding: 5px 10px;
}

.PARTNER-info .PARTNER-info-content-second {
  width: calc(100% - 211px);
  border: 2px solid var(--title-color);
}

.signature-content {
  margin-left: 30px;
  background-color: #d0d0d0;
  padding: 20px;
  border: 2px solid #d0d0d0;
  border-radius: 10px;
}

.main-signature-content {
  margin-bottom: 20px;
}

.round-EXECUTED {
  border: 2px solid #d5d5d5;
  background: #d5d5d5;
  border-radius: 10px;
  width: 200px;
  margin-right: 20px;
  padding: 15px;
}
.paddingNone {
  padding: 0 !important;
  text-align: center;
}

.box-EXECUTED-signature {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: column;
}

.first-EXECUTED-signature {
  display: flex;
  width: 100%;
}

.second-EXECUTED-signature {
  display: flex;
  width: 100%;
}

.second-EXECUTED-signature p {
  width: 33.33%;
  font-size: 12px;
  padding: 10px;
  font-weight: 700;
}

.box-EXECUTED-signature {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: column;
}

.main-signature-content {
  margin-bottom: 50px;
}

.content-AGREEMENT input {
  display: inline-block;
  margin-bottom: 5px !important;
}

.content-AGREEMENT .AGREEMENT-input {
  background: #d5d5d5;
  border: 2px solid #d5d5d5;
  border-radius: 20px;
}

.swal2-styled.swal2-confirm {
  background-color: var(--title-color) !important;
}

.swal2-title {
  font-size: 22px !important;
}

.swal2-html-container {
  font-size: 17px !important;
}

.swal2-styled.swal2-confirm:focus {
  box-shadow: 0 0 0 3px var(--title-color);
}

.swal2-actions:not(.swal2-loading) .swal2-styled:hover {
  color: #fff !important;
}

.check_YN_Bank input {
  width: auto;
}

.Subscription-fund {
  color: #fff;
}

.input-date .colorChanges {
  color: #fff;
}

.Signature-person {
  width: 100%;
}

.save-exit {
  position: absolute;
  width: 110px;
  top: -59px;
  right: 27px;
  min-height: 39px;
  background: var(--title-color);
  border: 2px solid var(--title-color);
  border-radius: 7px;
}

.save-exit p {
  color: #ffffff !important;
  top: 8.7px !important;
  font-size: 14px !important;
}

.info {
  width: 15px;
  float: right;
  position: absolute;
  bottom: 50px;
  font-size: 11px;
  right: 3px;
  top: 10px;
  cursor: pointer;
}

.form {
  max-width: 830px;
}

.heading__top h2 {
  font-size: 22px;
}
td.term-entity {
  width: 45%;
  text-align: center;
  background: #d0d0d0;
  margin-left: 20px;
  margin-right: 20px;
  border-radius: 10px;
}
.content-AGREEMENT .AGREEMENT-input {
  background: #d5d5d5;
  border: 2px solid #d5d5d5;
  border-radius: 20px;
  display: inline-block;
  width: 50%;
  text-align: center;
}
label.AGREEMENT-input-date {
  display: inline-block !important;
  margin-bottom: 0 !important;
  margin-left: 8px;
  margin-right: 8px;
}

.form.app_view_form {
  background: transparent;
  padding: 0;
}
.app_view_row {
  display: flex;
  justify-content: space-between;
}
.app_view_box {
  background: #fff;
  padding: 15px;
  border-radius: 4px;
  margin-bottom: 20px;
  box-shadow: 0 5px 5px #c7c7c7;
}
.app_view_box5 {
  width: calc(50% - 10px);
  position: relative;
}

.app_view h4 {
  font-size: 20px;
  font-weight: 700;
}

.app_view h4 {
  font-size: 18px;
  font-weight: 400;
  background: var(--title-color);
  color: #ffffff !important;
  padding: 10px;
  margin: -15px -15px 15px -15px;
  border-bottom: 2px solid var(--title-color);
}

.app_view p {
  margin-bottom: 5px;
  word-break: break-all;
}

.view_tabs .nav-pills .nav-link {
  /* border-radius: 0; */
  /* padding: 0 0 3px 0; */
  transition: 0.2s;
  cursor: pointer;
  padding: 8px 10px;
  border-radius: 0;
}
.nav-link a:hover {
  background-color: var(--title-color);
  color: #fff !important;
}
.view_tabs .nav-pills .nav-link.active,
.view_tabs .nav-pills .show > .nav-link {
  background-color: var(--title-color);
  color: #fff !important;
  /* border-bottom: 1px solid var(--title-color); */
  /* margin: 0 -15px;
  padding: 8px 15px; */
}

.table_dash thead {
  background: #d5d5d5;
  color: var(--title-color);
}

/* .history_table.table thead th {
  border: none;
} */
.view_logo img {
  filter: invert(1);
}

.table_dash.table td,
.table_dash.table th {
  padding: 10px 12px;
  font-weight: 400;
}

.primary_text {
  background: #d5d5d5;
  color: var(--title-color);
  /* margin: 0 -15px 20px -15px; */
  padding: 10px 15px;
  margin-bottom: 20px;
  display: flex;
  justify-content: space-between;
  margin-top: 2rem;
  align-items: center;
}
.aml_Details {
  width: 200px;
}
.row.signature_img {
  display: flex;
  align-items: center;
}
.document_download .downloads {
  width: 24px;
  height: 24px;
  background: var(--title-color);
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 8px;
}
.document_download .downloadeStyle {
  font-size: 12px;
  margin: 0;
}
.downloadeMsg {
  font-size: 13px;
  margin-top: 3px;
  margin-left: 0;
  display: flex;
}

.app_view_form label::first-line {
  /* text-transform: capitalize; */
}

.app_view_form label {
  /* text-transform: lowercase; */
}
.buttond_dashboard button {
  width: 100% !important;
  margin-bottom: 20px;
  background: var(--title-color);
  padding: 20px;
  font-weight: 500 !important;
  border: none;
  color: white;
}
.buttond_dashboard a {
  width: calc(50% - 20px) !important;
}
.buttond_dashboard a button {
  width: 100% !important;
}

.buttond_dashboard {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  align-items: center;
}
.edit_icon {
  display: flex;
  align-items: end;
  justify-content: flex-end;
}
.edit_icon i {
  font-size: 20px;
  cursor: pointer;
}
.heading__top.ht_heading {
  position: relative;
}
.heading__top.ht_heading img {
  width: 25px;
  margin-top: -6px;
  margin-right: 10px;
  display: inline-block;
  cursor: pointer;
}

.formPopUp.MuiBox-root.css-0 {
  background: white;
  max-width: 600px;
  margin: auto;
  top: 50%;
  position: absolute;
  left: 0;
  right: 0;
  transform: translateY(-50%);
  border-radius: 5px;
}
.css-7355d1-MuiBackdrop-root-MuiModal-backdrop {
  background: rgba(4, 4, 4, 0.4) !important;
}
.table.history_table.table tbody td {
  font-weight: 400;
}

.formPopUp {
  position: relative;
}
.edit_form {
  padding: 30px;
}
.formPopUp h4 {
  padding: 20px 30px;
  background: var(--title-color);
  color: #fff !important;
  margin-bottom: 0;
  margin-top: -1px;
  font-size: 20px;
}
.col-md-1.edit_icon {
  position: absolute;
  right: 0;
  bottom: 0;
}
.formPopUp input::placeholder {
  font-weight: 500 !important;
}
.submit_btn {
  display: flex;
  justify-content: flex-end;
}
.submit_btn.submit_btn_admin {
  justify-content: space-between;
}
button.change_btn {
  padding: 5px 10px;
  text-transform: capitalize !important;
}

.swal2-container {
  z-index: 2999;
}
.wholesale_cert {
  margin-bottom: 30px;
}
.wholesale_cert h3 {
  font-size: 18px;
  margin-bottom: 8px;
  margin-top: 15px;
}
.wholesale_cert h3:first-child {
  margin-top: 0;
}
.wholesale_cert p {
  margin-bottom: 8px;
  position: relative;
  padding-left: 20px;
}
.wholesale_cert_pl {
  padding-left: 15px;
  position: relative;
}

.wholesale_cert .arrow-Wholesale:after {
  content: '\f105';
  font-family: FontAwesome;
  color: var(--title-color);
  position: absolute;
  left: 5px;
  top: -1px;
  font-size: 18px;
  font-weight: 700;
}
.capitalname {
  color: var(--title-color);
  font-size: 14px;
  text-transform: uppercase !important;
}
/* 19 feb changes css ramneek */
/* .Main-wrapper-circle {
  max-width: 100px;
  vertical-align: middle;
  text-align: center;
}
.Main-wrapper-circle p.circle {
  text-align: center;
  width: 53px;
  border-radius: 50%;
  height: 53px;
  line-height: 1.5;
  padding-top: 12px;
}
.rwttax{
  position: relative;
}
.Main-wrapper-circle:before {
  content: "";
  border: 2px solid var(--title-color);
  width: 45px;
  height: 45px;
  position: absolute;
  border-radius: 50%;
  top: 3px;
  left: 4px;
} */
.main-padding-wrapper {
  padding-left: 0;
}
.whole_cert h3 {
  margin-bottom: 10px;
  font-size: 18px;
  margin-top: 15px;
}
.whole_cert h3:first-child {
  margin-top: 0;
}
/* .whole_cert p {
  position: relative;
  padding-left: 15px;
}
.whole_cert p:after {
  content: "\f105";
  font-family: FontAwesome;
  color: var(--title-color);
  position: absolute;
  left: 5px;
  top: -1px;
  font-size: 18px;
  font-weight: 700;
} */
.UpdateBtn.row {
  margin-top: 70px;
}
.UpdateBtn.row {
  display: flex;
  align-items: center;
}
.cancelBTN {
  font-size: 18px;
  text-align: center;
  cursor: pointer;
}
.UpdateBtn.row button {
  width: 100% !important;
  text-align: center;
  justify-content: center;
}

.UpdateApplication.edit_form select {
  width: 70%;
  border-bottom: none;
  padding-left: 0 !important;
}
.adminProfile {
  cursor: pointer;
  margin-top: 22px;
}
.adminProfile > a {
  text-align: center;
  width: 100%;
}
.SubscriptionAgree_btn,
.history {
  position: absolute;
  bottom: 10px;
}
.agrementspdf {
  margin-left: 10px;
  font-size: 1rem;
  cursor: pointer;
}
.SubscriptionAgree_btn a {
  margin-right: 10px;
}
span.date_c {
  min-width: 204px;
  display: inline-block;
  font-weight: 700;
  margin: 0 8px;
  padding: 2px 0;
  background: #d5d5d5;
  border: 2px solid #d5d5d5;
  border-radius: 20px;
  text-align: center;
}
.list_abc {
  padding-left: 40px;
}
.notes {
  min-height: 220px;
  background: #d8d8d8;
  padding: 15px;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.note.new {
  display: grid;
  flex-wrap: wrap;
  justify-content: start;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 20px;
}
.posta {
  display: flex;
  align-items: center;
  right: 0px;
  bottom: 5px;
  width: 100%;
  justify-content: space-between;
  margin-top: 35px;
}

.posta p:first-child {
  margin-right: 10px;
}

.notes textarea.overflow {
  width: 100%;
}
#table_id.dataTable.display tbody tr.odd > .sorting_1,
#table_id1.dataTable.display tbody tr.odd > .sorting_1,
#table_id2.dataTable.display tbody tr.odd > .sorting_1,
table.dataTable.order-column.stripe tbody tr.odd > .sorting_1 {
  background-color: #fff !important;
}

#table_ids.dataTable.display tbody tr.odd > .sorting_1,
#table_id1s.dataTable.display tbody tr.odd > .sorting_1,
#table_id2s.dataTable.display tbody tr.odd > .sorting_1,
table.dataTable.order-column.stripe tbody tr.odd > .sorting_1 {
  background-color: #fff !important;
}
#table_id,
#table_id1,
#table_id2 {
  border-collapse: collapse !important;
  margin-bottom: 10px;
}
#table_ids,
#table_id1s,
#table_id2s {
  border-collapse: collapse !important;
  margin-bottom: 10px;
}
#table_id.dataTable.stripe tbody tr.odd,
#table_id1.dataTable.stripe tbody tr.odd,
#table_id3.dataTable.stripe tbody tr.odd,
table.dataTable.display tbody tr.odd {
  background-color: #fff !important;
}
#table_ids.dataTable.stripe tbody tr.odd,
#table_id1s.dataTable.stripe tbody tr.odd,
#table_id3s.dataTable.stripe tbody tr.odd,
table.dataTable.display tbody tr.odd {
  background-color: #fff !important;
}
div#table_id_length,
div#table_ids_length,
div#table_id1_length,
div#table_id1s_length,
div#table_id2_length,
div#table_id2s_length,
#History_table_length,
#history_table_client_length {
  width: 34%;
}

#table_id_filter label,
#table_ids_filter label,
#table_id1_filter label,
#table_id1s_filter label,
#table_id2_filter label,
#table_id2s_filter label,
#History_table_filter label,
#history_table_client_filter label {
  text-align: right;
}
#table_id.dataTable.display tbody tr.even > .sorting_1,
#table_id1.dataTable.display tbody tr.even > .sorting_1,
#table_id2.dataTable.display tbody tr.even > .sorting_1,
table.dataTable.order-column.stripe tbody tr.even > .sorting_1 {
  background-color: #fff !important;
}
#table_id_length label,
#table_ids_length label,
#table_id1_length label,
#table_id1s_length label,
#table_id2_length label,
#table_id2s_length label,
#History_table_length label,
#history_table_client_length label {
  display: inline !important;
  margin-top: 9px;
}
#table_id_wrapper select,
#table_ids_wrapper select,
#table_id1_wrapper select,
#table_id1s_wrapper select,
#table_id2_wrapper select,
#table_id2s_wrapper select,
#History_table_wrapper select,
#history_table_client_wrapper select {
  display: inline-block !important;
  margin-top: 0;
}
#table_id_filter input,
#table_ids_filter input,
#table_id1_filter input,
#table_id1s_filter input,
#table_id2_filter input,
#table_id2s_filter input,
div#History_table_filter input,
#history_table_client_filter input {
  display: inline !important;
  width: 160px;
  margin-left: 10px;
}
#table_id table,
#table_id1 table,
#table_id2 table,
td,
th {
  /* border: 2px solid var(--title-color); */
  border: 2px solid #ddd;
}
.dataTables_length select {
  margin-left: 10px;
  margin-right: 5px;
}
.note-main-loader {
  position: relative;
}
.note-main-loader .loader {
  top: -47px;
  /* left: 100px; */
  right: 0;
  /* margin: auto; */
  width: 50px;
  height: 50px;
  /* border: 4px solid #f3f3f3;
  border-top: 4px solid var(--title-color) 000; */
  border: 8px solid var(--title-bg_color);
  border-top: 8px solid var(--border-color);
}
p.note_date_right {
  text-align: right;
  background: #e6e5e5;
  color: var(--title-color);
  margin: -15px;
  margin-bottom: 10px;
  padding: 6px 15px;
  font-size: 15px;
  border-bottom: 1px solid #a5a5a5;
}
.app_view_box.app_view_box5 .ui.icon.input > input {
  border: none !important;
  background: transparent !important;
  padding: 0 !important;
  margin-bottom: 0 !important;
}
span.text_upercas {
  text-transform: capitalize !important;
}

.app_view h4 span.text_upercas {
  font-size: 18px;
}

.table-term-condition,
td {
  font-weight: 400 !important;
}
.aml_fail {
  color: red;
  text-transform: uppercase;
}
.aml_pass {
  color: green;
  text-transform: uppercase;
}
.behalf_text {
  background: #d5d5d5;
  padding: 5px 20px;
  margin: 0 10px;
  border-radius: 6px;
}
.register_page {
  position: absolute;
  top: 40%;
  transform: translateY(-50%);
  left: 0;
  right: 0;
  border-radius: 6px;
  background-color: #ffffff !important;
}
.register_page h3 {
  text-align: center;
  font-size: 30px;
}
.register_page .fotter-btn {
  justify-content: center;
  margin-top: 35px;
}
.register_page button {
  font-size: 16px !important;
}

.dataTables_wrapper .dataTables_paginate .paginate_button {
  padding: 5px 12px;
}
.note_date {
  margin-bottom: 10px;
}

.modal-dialog {
  align-items: center;
  justify-content: center;
  display: flex;
  margin: auto;
  position: absolute;
  top: 50%;
  right: 0;
  left: 0;
  height: 100%;
  transform: translateY(-50%) !important;
  max-width: 640px;
}
.modal-body {
  height: 490px;
  overflow: auto;
  padding: 20px 25px;
}

.modal-dialog button.btn-close:after {
  content: '\f00d';
  font-family: FontAwesome;
}
.modal-dialog .modal-content select {
  width: 100%;
}
.modal-dialog .modal-content input,
.modal-dialog .modal-content select,
.modal-dialog .modal-content select .ant-input,
.modal-dialog .modal-content .ui.icon.input > input {
  width: 100%;
  box-shadow: none !important;
  border: 0;
  border-bottom: 1px solid var(--title-color);
}
.modal-dialog .field {
  width: 100%;
}
.modal-dialog .ant-row {
  margin-bottom: 10px;
  flex-direction: column;
}

.modal-dialog .modal-header h5 {
  color: #fff !important;
}

.modal-dialog .modal-header {
  background: var(--title-color);
  align-items: center;
}
.modal-dialog button.btn-close {
  background: white;
  color: var(--title-color) !important;
}

.Edit_button_style {
  background: var(--title-color);
  color: #fff !important;
  display: inline-block;
  padding: 10px 15px;
  border-radius: 5px;
  text-align: center;
  position: relative;
  /* max-width: 150px;
  width: 130px; */
}
.Edit_button_style:hover {
  color: #fff !important;
}
button.coffee-color.right-side.overwrite.d-table.ml-auto.mt-3.btn.btn-primary {
  background: var(--title-color);
  border: none;
}
input#imag2 {
  width: 100%;
  position: absolute;
  top: 0px;
  left: 0;
  padding: 0;
}

.input_check .ant-checkbox-checked .ant-checkbox-inner {
  background-color: #0c0c0c;
  border-color: var(--title-color) 000;
}

.input_check label.ant-checkbox-wrapper {
  display: flex !important;
}
.yes1_attach {
  /* max-width: 500px;
  width: 450px; */
  position: relative;
}

/* progress loader */

.progress {
  width: 100%;
  max-width: 260px;
  height: 6px;
  background: #e1e4e8;
  border-radius: 3px;
  overflow: hidden;
  margin: 12px 0;
}

.progress .progress-bar {
  display: block;
  height: 100%;
  background: linear-gradient(
    90deg,
    #555248,
    #757073 17%,
    #100d13 34%,
    #171e1e 51%,
    #d6d6cd 68%,
    #ffffff 69%,
    #665e5e
  );
  background-size: 300% 100%;
  animation: progress-animation 2s linear infinite;
}

@keyframes progress-animation {
  0% {
    background-position: 100%;
  }

  100% {
    background-position: 0;
  }
}
input#removeImage2 {
  width: 30px;
}
span#name_tag {
  font-size: 16px;
}
.Attch_file {
  margin: px 0;
  margin-top: 10px;
  margin-bottom: 20px;
  margin-left: 16px;
}
a.documentProof-downloade.confirmationDocument {
  display: inline-block;
  margin-left: 3px;
  vertical-align: middle;
}
a.licedocument-downloade {
  display: inline-block;
}
p.View__file {
  margin-left: 10px;
}
.swal2-title {
  font-size: 23px !important;
  font-weight: 400 !important;
}
.td__width {
  max-width: 300px !important;
  width: 200px !important;
}
.yes1_attach__notes {
  width: 90px;
  position: relative;
  max-width: 350px;
}
.flex__Note_atttach {
  display: flex;
  justify-content: space-around;
  align-items: center;
}
.flex__Note_atttach .rmv {
  background: var(--title-color);
  max-width: 28px;
  position: static;
  margin: 0 !important;
}
.flex__Note_atttach .it {
  width: 100% !important;
}
.btn_upload_note input {
  cursor: pointer;
  /* height: 100%; */
  position: absolute;
  filter: alpha(opacity=1);
  -moz-opacity: 0;
  background: red;
  opacity: 0;
  /* max-width: 200px;
  min-width: 100px;
  width: 118px; */
  width: 100%;
  top: 4px;
  left: 0;
}

.Edit_button_style_note {
  color: #fff !important;
  width: 100%;
}
/* .yes1_attach__notes {
  width: 100% !important;
  position: relative;
  background: var(--title-color);
  text-align: center;
  padding: 7px 10px;
  border-radius: 5px;
  color: #fff;
} */

.main_attach {
  display: flex;
  justify-content: start;
  align-items: center;
}
.flex__msg {
  margin: 10px;
}
div#History_table_length {
  margin: 10px !important;
}

/* div#history_table_client_length,
div#history_table_client_filter {
  margin: 20px !important;
}
div#History_table_length label,
div#history_table_client_length label {
  display: inline-flex !important;
  align-items: center;
}
div#History_table_length select,
div#history_table_client_length select {
  margin: 0 !important;
}
div#History_table_filter label,
div#history_table_client_filter label {
  display: inline-flex !important;
  align-items: center;
  justify-content: flex-end !important;
}
div#History_table_filter input,
div#history_table_client_filter input {
  display: inline-flex !important;
  margin-bottom: 0 !important;
} */

.PAdding__button {
  padding: 10px 15px !important;
}
h4.text__red {
  color: red !important;
}

.text__green {
  color: green !important;
}
.wrapper_director {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.Sign__agreement_ICON {
  padding: 10px;
}
@media (max-width: 575px) {
  .app_view_row {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .app_view_box5 {
    width: 100%;
  }
  .responsive__align {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
  }

  .button.update_stag {
    width: auto !important;
    padding: 5px 6px !important;
    font-size: 13px;
  }
  .SubscriptionAgree_btn {
    position: relative;
  }
  .responsive__SubscriptionAgreement {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 10px 0;
  }
  button.Sign__agreement {
    font-size: 12px !important;
    padding: 6px;
    letter-spacing: 1px;
    min-width: 142px;
    text-align: center;
    justify-content: center;
  }
  button.Sign__agreement.button__green {
    background-color: green;
    border: none;
  }
  button.Sign__agreement.button__red {
    background-color: red;
    border: none;
  }
  .right_content .form {
    padding: 20px 0 !important;
  }
  .app_view_box {
    padding: 10px !important;
  }
  .SubscriptionAgree_btn a {
    margin-right: 0px !important;
  }
  .right_content {
    margin-top: 50px;
  }
  .heading__top h2 {
    margin: 5px 0 15px 35px;
    font-size: 17px;
  }
  .buttond_dashboard button {
    margin-bottom: 20px;
  }

  .tab-pane {
    border-top: 1px solid grey;
  }
  tbody,
  td,
  tfoot,
  th,
  thead,
  tr {
    max-width: 200px;
  }
}

.text__aml_button {
  text-transform: capitalize !important;
}
/* a.click-hover.Edit_button_style:hover {
  background: #d5d5d5;
  display: inline-block;
  color: var(--title-color) !important;
  transition: 0.3s;
  transition: scale(1);
} */
i.fa.fa-history {
  font-size: 18px;
  line-height: 22px;
}
i.fa.fa-history:hover {
  color: var(--title-color);
  display: inline-block;
  font-size: 18px;
  transition: 0.3s;
  font-weight: 900;
  transform: scale(1.3);
  -webkit-transform: scale(1.3);
  padding: 0;
  margin: 0;

  /* transition: background-color 0.5s ease; */
}
i.fa.fa-arrow-left.left__arrow {
  color: #fff;
  font-size: 21px;
  font-weight: 900;
}
button:hover .left__arrow:hover {
  color: var(--title-color);
  font-size: 33px;
  font-weight: 900;
}
/* button:hover i.fa.fa-arrow-left.left__arrow:hover {
  color: var(--title-color);
  font-size: 33px;
  font-weight: 900;
  transition: 0.5s ease;
} */
.main_wrapper__heading {
  display: flex;
  align-items: center;
  padding-left: 30px;
  margin-bottom: 20px;
}
.IST_leftArrow {
  width: 80px;
  height: 55px;
  margin-top: 5px;
}
/* .hover__Edit:hover {
  font-size: 30px;
} */
/* i.fa.fa-eye:hover {
  color: var(--title-color);
  display: inline-block;
  font-size: 21px;
  transition: 0.3s;
  padding: 0;
  margin: 0;
} */
i.fa.fa-eye,
i.fa.fa-handshake-o,
i.fa.fa-pencil-square-o {
  font-size: 18px;
  line-height: 22px;
}
i.fa.fa-eye:hover {
  color: var(--title-color);
  display: inline-block;
  font-size: 18px;
  transition: 0.3s;
  font-weight: 900;
  transform: scale(1.3);
  -webkit-transform: scale(1.3);
  padding: 0;
  margin: 0;
}
.ant-form-item-control-input-content button:hover {
  background: #d8d8d8 !important;
  /* display: inline-block; */
  color: var(--title-color) !important;
}
.note-footer.flex-note {
  text-align: right;
  margin: 0 auto;
  width: 100%;
}
i.fa.fa-pencil-square-o:hover,
i.fa.fa-pencil-square-o:hover,
i.fa.fa-handshake-o:hover {
  color: var(--title-color);
  display: inline-block;
  font-size: 18px;
  transition: 0.3s;
  font-weight: 900;
  transform: scale(1.3);
  -webkit-transform: scale(1.3);
  padding: 0;
  margin: 0;
}
button.save {
  margin: 0;
  text-align: right;
  width: 100%;
  display: inline-block;
}
.buttond_dashboard button:hover {
  background: var(--title-color);
  color: #fff !important;
}
.row.justify__align {
  justify-content: space-between;
  align-items: center;
}

.IST_leftArrow button:hover i {
  color: red;
}
.mb-bottom {
  display: inline-block;
}

/* table.dataTable thead .sorting_asc {
  background-image: none !important;
} */
.text_trns {
  text-transform: capitalize !important;
}
@media (max-width: 575px) {
  .display__mobile {
    display: none;
  }
  .buttond_dashboard button {
    padding: 10px 12px !important;
    font-size: 14px !important;
  }

  .form.director {
    padding: 10px !important;
  }
  h4.drop_icon {
    display: inline-block;

    font-weight: 700;
  }
}
button.change__button {
  padding: 4px 10px;
  font-size: 13px !important;
}
h4.drop_icon {
  display: inline-block;
}

button.update_stag {
  /* max-width: 134px;
  width: 170px !important; */
  padding: 6px 15px;
  text-align: center;
  justify-content: center;
}
.table.dataTable thead th,
table.dataTable thead td {
  border-bottom: none !important;
}
table.dataTable thead th,
table.dataTable thead td {
  border-bottom: none !important;
}
table.dataTable.no-footer {
  border-bottom: none !important;
}
.main-downloade-wrapper {
  align-items: center;
}
.text__red,
.text__reds {
  color: red !important;
}
.text__green,
.text__greens {
  color: green !important;
}
.text__green:hover {
  color: green !important;
  font-size: 18px;
  transition: 0.3s;
  font-weight: 900;
  transform: scale(1.3);
  -webkit-transform: scale(1.3);
  padding: 0;
  margin: 0;
}
.text__red:hover {
  color: red !important;
  font-size: 18px;
  transition: 0.3s;
  font-weight: 900;
  transform: scale(1.3);
  -webkit-transform: scale(1.3);
  padding: 0;
  margin: 0;
}
.history__pdfr {
  position: absolute;
  bottom: 10px;
  right: 10px;
}
.BITTON__sign_directore {
  display: inline-block;
}
p.entityename__dirctore {
  font-weight: 700;
  font-size: 18px;
  margin-bottom: 25px;
  line-height: 32px;
}
.hover_transition_none:hover {
  transform: none;
}
/* .inline_block_main {
  display: inline-block;
}
.DIRECTORE__rntityname {
  display: inline-block;
} */
.DIRECTORE__H {
  display: inline-block;
}
.entityename__dirctore {
  display: inline-block;
  margin-left: 7px;
}
/* @media screen and (min-width: 1366px) {
  .DIRECTORE__H {
    display: block;
  }
  .entityename__dirctore {
    display: block;
  }
} */

@media screen and (min-width: 1400px) {
  .wrapper_director {
    justify-content: flex-start;
  }
  .red_green_text {
    flex: 1;
  }
  /* .director h4 {
    text-align: right !important;
  } */
  .SIGNED__Directors {
    text-align: left !important;
  }
}

/* @media screen and (min-width: 1200px) {
  .red_green_text {
    flex: 1;
  }
  p.entityename__dirctore {
    margin-bottom: 0 !important;
  }
  .director h4 {
    margin-bottom: 0 !important;
  }
  h4.text__reds {
    text-align: right;
  }
  .red_green_text {
    margin-bottom: 15px;
  }
} */

@media screen and (min-width: 900px) {
  .wrapper_director {
    flex-wrap: wrap;
  }
  .red_green_text {
    width: 100%;
    margin-bottom: 10px;
  }
  /* .director h4 {
    text-align: right;
  } */
  p.entityename__dirctore {
    margin-bottom: 0;
  }
}
.SIGNED__h4 {
  text-align: right;
}
h4.drop_icon.Completed_apllicatin_icon {
  width: 100%;
  max-width: 149px;
}
.ppp {
  /* background: #d5d5d5; */
  min-height: 1000px;
  height: 100%;
}

.aml_fail__aml {
  display: inline-block;
  margin-right: 6px;
}
.aml_pass_aml {
  color: green;
}
.css-twnr3r {
  background-image: url(./pages/home-hero.jpg) !important;
  background-repeat: no-repeat !important;
  background-position: center !important;
  background-size: cover !important;
  padding: 40px 10px;
  min-height: 100vh;
  background-attachment: fixed !important;
}
@media (min-width: 900px) {
  .css-twnr3r {
    background-color: transparent !important;
  }
}

.row.responsive__align.main_wrapper_date {
  position: relative;
}

.main_date_stage {
  position: absolute;
  left: 44%;
  width: 24%;
  text-align: center;
}

.loader_date {
  display: inline-block;
  width: 30px;
  height: 30px;
  position: absolute;
  z-index: 3;
  border: 4px solid var(--title-color);
  top: 50%;
  animation: loader 2s infinite ease;
}

.loader-inner_date {
  vertical-align: top;
  display: inline-block;
  width: 100%;
  animation: loader-inner 2s infinite ease-in;
}

@keyframes loader_date {
  0% {
    transform: rotate(0deg);
  }

  25% {
    transform: rotate(180deg);
  }

  50% {
    transform: rotate(180deg);
  }

  75% {
    transform: rotate(360deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

@keyframes loader-inner_date {
  0% {
    height: 0%;
  }

  25% {
    height: 0%;
  }

  50% {
    height: 100%;
  }

  75% {
    height: 100%;
  }

  100% {
    height: 0%;
  }
}
.main_date_loader {
  position: relative;
  width: 100%;
  height: 30px;
  right: -70px;
  top: -8px;
}

.main_date_loader_duval {
  position: relative;
  width: 100%;
  height: 30px;
  right: -70px;
  top: -39px;
}
.main_date_loader_duval .loader {
  border: 4px solid #f3f3f3;
  border-radius: 50%;
  border-top: 4px solid var(--title-color) 000;
  width: 30px;
  height: 30px;
  animation: spin 2s linear infinite;
  position: absolute;
  right: -47px;
  top: -4px;
}

.main_date_loader .loader {
  border: 4px solid #f3f3f3;
  border-radius: 50%;
  border-top: 4px solid var(--title-color) 000;
  width: 30px;
  height: 30px;
  animation: spin 2s linear infinite;
  position: absolute;
  right: -47px;
  top: -4px;
}

.main_date_loader .loader-inner {
  vertical-align: top;
  display: inline-block;
  width: 100%;
  animation: loader-inner 2s infinite ease-in;
}
.main_wrappr_agrementspdf {
  position: relative;
  /* margin-top: 10px; */
  min-height: 92px;
  bottom: -13px;
  right: -13px;
}

/* media css */

@media screen and (min-width: 576px) {
  .app_view_row {
    flex-direction: column;
  }
  .app_view_box5 {
    width: 100%;
  }
  .app_view p {
    word-break: break-word;
    /* font-size: 13px; */
  }
  .right_content {
    margin-top: 0px;
  }
  label {
    font-size: 13px;
  }
  .PAdding__button {
    font-size: 12px !important;
    padding: 10px 12px !important;
  }
}
@media screen and (min-width: 768px) {
  .app_view_row {
    flex-direction: column;
  }
  .app_view_box5 {
    width: 100%;
  }
  .app_view p {
    word-break: break-word;
    font-size: 16px;
  }
}

@media screen and (min-width: 992px) {
  .app_view_row {
    flex-direction: row;
  }
  .app_view_box5 {
    width: calc(50% - 10px);
  }
  label {
    font-size: 14px;
  }
  .PAdding__button {
    font-size: 14px !important;
    padding: 10px 15px !important;
  }
  .app_view h4 {
    margin: -15px -15px 15px -15px;
  }
}

@media screen and (max-width: 991px) {
  .responsive__SubscriptionAgreement {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin: 10px 0;
    position: relative;
  }
}
@media screen and (min-width: 1380px) {
  .dataTables_wrapper.no-footer .dataTables_scrollBody {
    border: none !important;
  }
}

@media screen and (min-width: 576px) {
  .show_responsive {
    display: none;
  }
  i.ICON__Arrow {
    background: var(--title-color);
    color: #fff;
    display: inline-block;
    /* padding: 13px; */
    border: 2px solid var(--title-color);
    border-radius: 5px;
    width: 50px;
    height: 50px;
    vertical-align: middle;
    font-size: 23px;
    line-height: 32px;
    text-align: center;
    padding: 6px 0;
    margin-right: 14px;
  }
}
@media screen and (max-width: 767px) {
  .view_tabs.responsive_hide {
    display: block;
  }
  .myfarm_form button {
    min-height: 150px;
  }
  .note.new {
    grid-template-columns: auto;
  }
  div#table_id_length,
  div#table_ids_length,
  div#table_id1_length,
  div#table_id1s_length,
  div#table_id2_length,
  div#table_id2s_length,
  #History_table_length,
  #history_table_client_length {
    width: 100%;
    display: flex;
    align-items: center;
    /* justify-content: center; */
  }
  #table_id_length label,
  #table_ids_length label,
  #table_id1_length label,
  #table_id1s_length label,
  #table_id2_length label,
  #table_id2s_length label,
  #History_table_length label,
  #history_table_client_length label {
    display: flex !important;
    margin-top: 9px;
    align-items: center;
    /* margin-right: 192px; */
  }
  div#table_id_filter,
  div#table_ids_filter,
  div#table_id1_filter,
  div#table_id1s_filter,
  div#table_id2_filter,
  div#table_id2s_filter,
  div#History_table_filter,
  div#history_table_client_filter {
    display: flex;
    width: 100%;
    /* justify-content: center; */
    align-items: center;
  }
  #table_id_wrapper select,
  #table_ids_wrapper select,
  #table_id1_wrapper select,
  #table_id1s_wrapper select,
  #table_id2_wrapper select,
  #table_id2s_wrapper select,
  #History_table_wrapper select,
  #history_table_client_wrapper select {
    margin-bottom: 0 !important;
  }
}
@media screen and (max-width: 575px) {
  .show_responsive {
    display: block;
  }
  button.update_stag {
    width: auto !important;
    padding: 5px 9px !important;
    font-size: 13px !important;
  }
  .main_date_stage {
    position: relative;
    width: 100%;
    padding-left: 15px;
    left: 0;
    text-align: left;
  }
  .main_wrappr_agrementspdf {
    right: 9px;
  }
  button.mb-bottom.Subscription_button {
    padding: 5px 10px !important;
    width: 100% !important;
    font-size: 13px !important;
  }
  .tab-pane {
    padding: 10px;
  }
  .note.new {
    grid-template-columns: auto;
  }
  .ICON__Arrow {
    width: 35px;
    height: 35px;
    line-height: 20px;
    margin-left: 6px;
    font-size: 17px;
  }

  .main_header_all_user {
    font-size: 16px;
    text-align: center;
    margin-top: 10px;
  }

  .app_view h4 {
    margin: 10px auto;
  }
  .responsive__SubscriptionAgreement {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 10px 0;
    position: relative;
  }
  .director h4 {
    font-size: 15px !important;
  }
  .edit_icon {
    justify-content: flex-end;
  }
  .history__pdfr {
    right: 0 !important;
  }
  .history {
    bottom: 15px !important;
    right: 15px;
  }
  .yes1_attach {
    position: relative;
    /* text-align: right; */
  }
  .view_tabs.responsive_hide {
    display: none;
  }
  .col-md-1.edit_icon {
    position: relative;
    right: 0;
    bottom: 0;
  }
  .app_view p {
    word-break: break-word !important;
  }

  .aml_Details .pass__aml-right {
    text-align: right;
  }
  .PersonalInformationEdit {
    position: relative !important;
  }
}
.dataTables_wrapper.no-footer .dataTables_scrollBody {
  border-bottom: none !important;
}
.PersonalInformationEdit {
  justify-content: flex-end;
  position: absolute;
  bottom: 0;
  right: 0;
}
.accordion-item .accordion-button {
  width: 100% !important;
  padding: 10px !important;
  font-size: 16px;
  font-weight: 400;
  color: var(--title-color) !important;

  border-radius: 1px !important;
}
.accordion-button:not(.collapsed) {
  background: var(--title-color);
  color: #fff !important;
}

.accordion-item:first-of-type .accordion-button {
  border-top-left-radius: calc(0.25rem - 1px);
  border-top-right-radius: calc(0.25rem - 1px);
}
button:focus:not(:focus-visible) {
  outline: 0;
}
button:focus:not(:focus-visible) {
  outline: 0;
}
.accordion-button:focus {
  z-index: 3;
  border-color: transparent;
  outline: 0;
  box-shadow: none;
}

.css-16dqz1z-MuiButtonBase-root-MuiListItemButton-root:hover {
  background-color: var(--title-color) 000fa;
  color: #fff !important;
}
.css-16dqz1z-MuiButtonBase-root-MuiListItemButton-root {
  background-color: var(--title-color) 000fa;
  color: #fff !important;
}
.css-7oyo6l-MuiButtonBase-root-MuiListItemButton-root:hover {
  background-color: rgb(216, 216, 216);
}
.accordion-header:active .accordion-button {
  background-color: var(--title-color);
  color: #fff !important;
}
.PARTNER-info .PARTNER-info-content-second {
  word-break: break-all;
}

/* manish */

/* .view_tabs .nav-pills .nav-link:hover {
  background-color: var(--title-color);
  color: #fff;
} */
svg {
  font-size: 25px;
}
table.dataTable {
  border-collapse: collapse !important;
}
table.dataTable thead > tr > th.sorting:before,
table.dataTable thead > tr > th.sorting_asc:before,
table.dataTable thead > tr > th.sorting_desc:before,
table.dataTable thead > tr > th.sorting_asc_disabled:before,
table.dataTable thead > tr > th.sorting_desc_disabled:before,
table.dataTable thead > tr > td.sorting:before,
table.dataTable thead > tr > td.sorting_asc:before,
table.dataTable thead > tr > td.sorting_desc:before,
table.dataTable thead > tr > td.sorting_asc_disabled:before,
table.dataTable thead > tr > td.sorting_desc_disabled:before {
  opacity: 0;
}
table.dataTable thead > tr > th.sorting:after,
table.dataTable thead > tr > th.sorting_asc:after,
table.dataTable thead > tr > th.sorting_desc:after,
table.dataTable thead > tr > th.sorting_asc_disabled:after,
table.dataTable thead > tr > th.sorting_desc_disabled:after,
table.dataTable thead > tr > td.sorting:after,
table.dataTable thead > tr > td.sorting_asc:after,
table.dataTable thead > tr > td.sorting_desc:after,
table.dataTable thead > tr > td.sorting_asc_disabled:after,
table.dataTable thead > tr > td.sorting_desc_disabled:after {
  opacity: 0;
}

.ant-form-item-control-input-content button {
  padding: 12px 15px;
}
.ant-form-item-control-input-content button:hover {
  background: var(--title-color) !important;
  color: #fff !important;
}
.attach_aml .Edit_button_style {
  max-width: 100% !important;
  width: auto;
}

.dataTables_wrapper {
  position: relative;
  clear: both;
  max-width: 100%;
  height: 100%;
}
table.dataTable tbody th,
table.dataTable tbody td {
  white-space: nowrap;
}

.align_item_Eligible {
  align-items: center;
  margin-top: 10px;
}

.button_changes_layout {
  /* border: 2px solid red; */
  display: flex;
  justify-content: flex-end;
}
button.dirctore_button_red {
  background: red !important;
  border: red;
}
button.dirctore_button_green {
  background: green;
  border: green;
}

.magin-space {
  margin-top: 40px;
}

.TextIndent {
  text-indent: 5px;
}
.negavtive-text-indent {
  text-indent: -5px;
}

h3.SIGNED__Directors {
  font-weight: 500 !important;
  font-size: 20px !important;
  margin-bottom: 10px !important;
}

.app_view p {
  cursor: context-menu;

  /* font-size: 13px; */
  color: var(--text-color);
  font-weight: 400;
}

span.capital {
  text-transform: uppercase;
}
p.aml_fail {
  color: RED;
}
p.aml_pass {
  color: green;
}
.paragraph {
  font-size: 16px;
}
.stage {
  font-size: 16px;
  font-family: 'Roboto', sans-serif;
  /* padding-top: 6px; */
  padding-right: 7px;
}
.rwt_exemption {
  font-size: 13px !important;
  font-family: 'Roboto', sans-serif !important;
  font-weight: 500 !important;
}
.col-md-12.edit_icon.iconn {
  margin-top: 10.8rem;
}

/* .modals {
  position: relative !important;
} */
.modalsClass {
  display: block !important;
}
.popup {
  display: none;
}

.custom_modal {
  background: white;
  max-width: 600px;
  margin: auto;
  top: 50%;
  position: fixed;
  left: 0;
  right: 0;
  transform: translateY(-50%);
  border-radius: 5px;
  z-index: 1;
}
.update_status {
  background-color: gray;
}
.update_status_button {
  display: flex;
  padding-right: 80px !important;
}

.custom_modal h4 {
  margin: 0;
  padding: 20px 30px;
}
.custom_modal .UpdateApplication select#StageChange {
  border-bottom: 1px solid black;
  margin: 0;
  width: 100%;
}
/* .update_status {
  background: var(--title-color);
} */
.update_status_button {
  align-items: center;
}

.modall {
  /* border: 1px solid black; */
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  box-shadow: rgba(0, 0, 0, 0.25) 0px 54px 55px, rgba(0, 0, 0, 0.12) 0px 54px 55px,
    rgba(0, 0, 0, 0.12) 0px 54px 55px, rgba(0, 0, 0, 0.17) 0px 54px 55px,
    rgba(0, 0, 0, 0.09) 0px 54px 55px;
}
.aml_id {
  /* padding-bottom: 6px; */
  text-transform: capitalize !important;
}
.myloader {
  right: 13rem !important;
  top: -10.6rem !important;
}
.border {
  /* border: 1px solid #dee2e6!important; */
  border: none !important;
}
.myfarm_com {
  margin-top: 1rem;
}

th {
  background-color: var(--title-color) !important;
  color: var(--white) !important;
}

.myfarm_form button {
  min-height: 182px;
  justify-content: center;
  flex-wrap: wrap;
  /* max-width: 256px; */
  border-radius: 50px !important;
}

.myfarm_form button h5 {
  width: 100%;
  margin-bottom: 10px;
  text-align: center;
  font-size: 30px;
  color: white !important;
  word-break: break-all;
}

.myfarm_form button p {
  max-width: 131px;
  text-align: center;
}

.myfarm_form button:hover h5 {
  color: #fff !important;
}
.note-load {
  margin-top: 8rem;
  margin-right: 9rem;
}
.myfarm_com22 {
  padding-left: 12px;
}
/* @media screen and (min-width: 1773px) { */
/* table.responsive.nowrap.dataTable.no-footer {
  width: 81.813rem !important;
} */
/* } */

.notes_buttonAttach:hover .Edit_button_style_note {
  color: #966a4d !important;
}

.notes_buttonAttach:hover {
  background: #fff;
}

button.save:hover {
  background: #fff;
  color: #966a4d !important;
}

a.licedocument-downloade p.downloadeMsg {
  cursor: pointer;
}
.error {
  color: red;
}
.Pass {
  color: green !important;
}
.aml_fail p {
  color: red;
}
@media (max-width: 575px) {
  .dataTable tbody,
  td,
  tfoot,
  th,
  thead,
  tr {
    max-width: 100%;
  }
}
.accordion-button:not(.collapsed)::after {
  background-image: url(../src/download-svg.svg);
}
table.dataTable thead .sorting_asc {
  background-image: url(../src/sort.png) !important;
}
.noteload_loader .loader {
  /* transform: translate(-50%, -50%); */
  left: 100px;
  bottom: 0;
}

.submit_btn_admin button:hover {
  background: transparent;
  color: #966a4d !important;
}
.MuiBox-root.css-1jliix button:hover {
  background: transparent;
  color: #966a4d !important;
}
